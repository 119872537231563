<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("edit") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-autocomplete
                :search-input.sync="searchTerm"
                :items="schoolItems"
                v-model="data.subject"
                item-text="name"
                item-value="id"
                outlined
                :label="$t('grade')"
                :menu-props="{ closeOnClick: true }"
              >
                <template v-if="!stopInfinityScroll" v-slot:append-item>
                  <div
                    v-intersect="onIntersect"
                    class="pa-4 teal--text justify-center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Subject } from "@/store/common/subject";
export default {
  props: ["dialog", "dialogData", "loader", "schoolItems"],
  data() {
    return {
      data: {},
      Subject,
      searchTerm: "",
      page: 1,
    };
  },
  methods: {
    submit() {
      Object.assign(this.data, {
        subject_id: this.data.subject,
      });
      delete this.data["subject"];
      if (typeof this.data.subject_id == "object")
        this.data.subject_id = this.data.subject_id.id;
      for (let key in this.data) {
        if (this.data[key] === this.dialogData[key]) {
          delete this.data[key];
        }
      }
      this.$emit("editData", { data: this.data, id: this.dialogData.id });
    },
    async onIntersect() {
      this.page += 1;
      await this.$emit("getShcools", {
        page: this.page,
        name: this.searchTerm,
      });
    },
  },
  computed: {
    validate() {
      return this.data.name == "" || this.data.subject == null;
    },
    stopInfinityScroll() {
      return Subject.getState.value.Subjects == 0;
    },
  },

  watch: {
    dialogData(val) {
      this.data = { ...val };
    },
    searchTerm(val) {
      setTimeout(() => {
        this.page = 1;
        this.$emit("getShcools", { page: this.page, name: val });
      }, 500);
    },
  },
};
</script>
